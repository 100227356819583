.navMenu {
  background-color: #31a1b3 !important;
  color: white !important;
  width: max-content !important;
  border: none;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;


}


.navMenu:hover {
  background-color: #227986 !important;
}
.active {
  background-color: #227986 !important;
}
.cursorPointer {
  cursor: pointer;
}

.walletButton {
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  border-radius: 12px;
  background-color: cornflowerblue !important;
  color: white !important;
}

@media only screen and (max-width: 600px) {
.navMenu{

  padding:6px 10px;
}
}
.bgColor{
background-color:   rgba(0, 0, 0, 0.5) !important;
color: white;
}